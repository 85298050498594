
export const ApiRoutes = {

  API_HOSTNAME: process.env.REACT_APP_API_HOSTNAME,
  GETNOTES: "getnotes",
  SUPERADMINLOGIN: "superadminlogin",

  //ORGANIZATION
  INSERTORGANIZATION : 'insertorganization',
  GETORGANIZATION : "getorganization",
  UPDATEORGANIZATION : "updateorganization",
  DELETEORGANIZATION : "deleteorganization",
  UPDATESTATUS : "updatestatus",

  //STORE
  INSERTSTORE : "insertstoredata", 
  GETSTOREDETAILS : "getstoredetails",
  UPDATESTORE: "updatestoredata",
  UPDATESTORESTATUS:"updatestorestatus",
  GETEXPIREDSTOREDETAILS:"getexpiredstoredetails",
  COUNTORGANIZATIONANDSTOREEXPIRE:'countorganizationandexpirestore'
};
