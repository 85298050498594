// ** Reducers Imports
import navbar from '../navbar'
import layout from '../layout'
// import auth from '../authentication'
import organizationReducer from './organizationReducer'
import storeReducer from './storeReducer'
import categoryReducer from './categoryReducer'

const rootReducer = {
  // auth,
  navbar,
  layout,
  organizationReducer,
  storeReducer,
  categoryReducer
}

export default rootReducer
